@charset "UTF-8";

:root {
  --bright-orange-color: #F89C26;
  --light-yellow-color: #DCD291;
  --white-color: #FFF;
  --grey-color: #DDD;
  --black-color: #2B2B2B
}

.gc-main-content {
  opacity: 0;
  transition: .3s all linear
}

.dark-theme .loader__wrapper {
  background: var(--black-color)
}

.loader__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  background: var(--white-color)
}

.loader__wrapper__item {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 200px);
  padding: 0 100px;
  transform: translate(-50%, -50%);
  color: var(--bright-orange-color);
  text-align: center
}

.loader__wrapper__item label {
  display: inline-block;
  opacity: 0;
  font-size: 20px
}

@keyframes lol {
  0% {
    opacity: 0;
    transform: translateX(-300px)
  }

  33% {
    opacity: 1;
    transform: translateX(0px)
  }

  66% {
    opacity: 1;
    transform: translateX(0px)
  }

  100% {
    opacity: 0;
    transform: translateX(300px)
  }
}

@-webkit-keyframes lol {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-300px)
  }

  33% {
    opacity: 1;
    -webkit-transform: translateX(0px)
  }

  66% {
    opacity: 1;
    -webkit-transform: translateX(0px)
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(300px)
  }
}

.loader__wrapper__item label:nth-child(6) {
  -webkit-animation: lol 3s infinite ease-in-out;
  animation: lol 3s infinite ease-in-out
}

.loader__wrapper__item label:nth-child(5) {
  -webkit-animation: lol 3s 100ms infinite ease-in-out;
  animation: lol 3s 100ms infinite ease-in-out
}

.loader__wrapper__item label:nth-child(4) {
  -webkit-animation: lol 3s 200ms infinite ease-in-out;
  animation: lol 3s 200ms infinite ease-in-out
}

.loader__wrapper__item label:nth-child(3) {
  -webkit-animation: lol 3s 300ms infinite ease-in-out;
  animation: lol 3s 300ms infinite ease-in-out
}

.loader__wrapper__item label:nth-child(2) {
  -webkit-animation: lol 3s 400ms infinite ease-in-out;
  animation: lol 3s 400ms infinite ease-in-out
}

.loader__wrapper__item label:nth-child(1) {
  -webkit-animation: lol 3s 500ms infinite ease-in-out;
  animation: lol 3s 500ms infinite ease-in-out
}

.gc-account-leftbar {
  width: 70px;
  background: var(--grey-color)
}

.gc-account-user-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start
}

.gc-account-leftbar .notify-count {
  right: -17px !important;
  bottom: 8px !important;
  padding: 0 !important;
  background: #FFF0 !important;
  font-size: 16px;
  color: #000 !important
}

.gc-account-leftbar .gc-account-user-menu .menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  margin-bottom: 10px !important
}

.gc-account-leftbar .gc-account-user-menu .menu-item * {
  transition: .3s all linear
}

.gc-account-leftbar .gc-account-user-menu .menu-item .custom-icon {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.gc-account-leftbar .gc-account-user-menu .menu-item .custom-icon:hover svg {
  opacity: 1 !important
}

.gc-account-leftbar .gc-account-user-menu .menu-item .custom-icon svg {
  height: 30px;
  width: 30px;
  margin: 0;
  opacity: .4
}

.gc-account-leftbar .gc-account-user-menu .menu-item:first-child {
  margin-top: 30px !important
}

.gc-account-leftbar .gc-account-user-menu .menu-item.menu-item-chatium,
.gc-account-leftbar .gc-account-user-menu .menu-item:not(.menu-item-profile) .menu-item-icon {
  display: none
}

.gc-account-leftbar .gc-account-user-menu .menu-item a:hover {
  background-color: #FFF0 !important
}

.gc-account-leftbar .gc-account-user-menu .menu-item.active a,
.gc-account-leftbar .gc-account-user-menu .menu-item a:hover .custom-icon {
  background: var(--light-yellow-color) !important
}

.gc-account-leftbar .gc-account-user-menu .menu-item.selected a {
  background: var(--white-color) !important
}

.gc-account-leftbar .gc-account-user-menu .menu-item.active svg,
.gc-account-leftbar .gc-account-user-menu .menu-item.selected svg {
  opacity: 1
}

.gc-account-leftbar .gc-account-user-menu .menu-item.menu-item-profile a {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start
}

.gc-account-leftbar .gc-account-user-menu .menu-item .menu-item-notifications_button_small .notify-count {
  bottom: 9px
}

.selected .custom-icon:hover {
  background: var(--light-yellow-color)
}

.gc-account-leftbar .gc-account-user-menu .menu-item a {
  width: 100%;
  height: 50px;
  padding: 0 !important;
  border-radius: 5px;
  background: #FFF0;
  overflow: hidden
}

.gc-account-user-submenu-bar .gc-account-user-submenu li .notify-count {
  font-size: 16px !important
}

.gc-account-user-submenu-bar .gc-account-user-submenu li a {
  padding: 10px 20px;
  font-size: 12px;
  line-height: 1
}

.menu-item.menu-item-theme {
  position: absolute !important;
  bottom: 5px;
  left: 14px
}

.dark-theme {
  background: var(--black-color)
}

.dark-theme p,
.dark-theme table *,
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6,
.dark-theme .part-text .f-text,
.dark-theme .lesson-description-value,
.dark-theme .gc-account-leftbar path,
.dark-theme .gc-account-leftbar .notify-count,
.dark-theme .gc-account-leftbar .gc-account-user-submenu-bar *,
.dark-theme .stream-table tr td .stream-title,
.dark-theme .gc-tags .no-tags,
.dark-theme .gc-remove-btn {
  color: var(--white-color) !important
}

.dark-theme .gc-account-leftbar,
.dark-theme .gc-account-user-submenu-bar .gc-account-user-submenu li a:hover,
.dark-theme .modal-content,
.dark-theme .gc-fileservice-remove-files.fixed {
  background: #373737
}

.dark-theme .gc-account-leftbar .gc-account-user-submenu-bar {
  background: #535353
}

.dark-theme .gc-account-leftbar .menu-item .custom-icon svg {
  opacity: .6 !important
}

.dark-theme .gc-account-leftbar path,
.dark-theme .gc-main-content:not(.lesson-list) a:focus,
.dark-theme .gc-main-content a:hover {
  fill: var(--white-color);
  color: var(--bright-orange-color)
}

.dark-theme .gc-fileservice-panel {
  background: var(--black-color)
}

.dark-theme .lesson-list li .description {
  color: #C8C8C8 !important
}

.dark-theme .close,
.dark-theme .lesson-list li :not(td) {
  color: var(--white-color)
}

.dark-theme .lesson-list li>a {
  border: 2px solid #979797;
  border-radius: 5px !important
}

.dark-theme .lesson-list li>a .info {
  border: none !important
}

.dark-theme .standard-page-menu li > a {
  color: var(--white-color) !important;
  border: 2px solid #0FF3AF
}

.dark-theme .gc-fileservice-progressbar,
.dark-theme .gc-fileservice-table-head {
  background: #444
}

.dark-theme .gc-fileservice-file-type span:nth-child(1) {
  color: var(--black-color) !important
}

.dark-theme .user-state-need_accomplish .user-state-label {
  color: #E74C3C !important
}

.dark-theme .user-state-need_accomplish {
  background: #400b0b
}

.dark-theme .gc-main-content .o-lt-tsr .lt-tsr-block,
.dark-theme .lesson-list,
.dark-theme .lesson-list li :not(td) {
  background: #FFF0 !important;
  background-color: #FFF0 !important
}

.dark-theme .gc-account-leftbar .menu-item-user.custom-icon path,
.dark-theme .gc-account-leftbar .menu-item.menu-item-sales path,
.dark-theme .gc-account-leftbar .menu-item.menu-item-theme path {
  stroke: var(--white-color);
  fill: #FFF0 !important
}

.dark-theme .stream-table tr td {
  background: #3C3C3C !important
}

.dark-theme .stream-table tr td:hover {
  background: #1C1C1C !important
}

.dark-theme .gc-account-leftbar .menu-item a:hover .custom-icon,
.dark-theme .gc-account-leftbar .menu-item.active a {
  background: #595959 !important
}

.lesson-list {
  background: #FFF0
}

.stream-table tbody {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 30px
}

.stream-table tr {
  display: block;
  flex: 0 1 48%;
  margin: 0 auto 10px !important
}

.stream-table tr td {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 180px;
  width: 100%;
  padding: 30px !important;
  border: 2px solid var(--light-yellow-color);
  border-radius: 6px;
  background: var(--white-color)
}

.stream-table tr td .stream-title {
  display: block;
  padding: 10px 0 !important;
  color: #000;
  font-weight: 600
}

.stream-table tr td a>div,
a {
  color: #777
}

a:hover,
a:focus {
  color: #000;
  text-decoration: none
}

.stream-table tr td a div>b {
  position: absolute;
  left: 50%;
  top: -1px;
  width: 135px;
  padding: 2px 0 4px 0;
  transform: translateX(-50%);
  border-radius: 0 0 5px 5px;
  background: var(--light-yellow-color);
  text-align: center;
  color: #5D5D5D !important
}

.lesson-list li {
  border: 2px solid var(--light-yellow-color);
  border-radius: 6px !important
}

.lesson-header-block {
  margin-bottom: 60px !important;
  border-bottom: none !important;
  background: #FFF0 !important
}

.gc-main-content .standard-page-content .block-set .col-md-4,
.gc-main-content .standard-page-content .block-set .col-md-6 {
  flex: 1 1 50%;
  margin: 0 auto !important
}

.gc-main-content .standard-page-content .block-set .col-md-8 {
  width: 100%;
  margin: 0 auto !important
}

.o-lt-lesson p,
.o-lt-lesson ul,
.o-lt-lesson ol,
.o-lt-lesson li,
.o-lt-lesson a {
  font-family: 'Raleway' !important;
  font-size: 18px;
  line-height: 1.4 !important
}

.o-lt-tsr .lt-tsr-block {
  padding-top: 0 !important;
  background-color: #F0F0F0
}

.o-lt-tsr .lt-tsr-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start
}

.lt-tsr-block .lt-tsr-content * {
  width: 100%
}

.o-lt-tsr .header {
  order: 2;
  font-weight: 600
}

.o-lt-tsr .subheader {
  order: 3
}

.o-lt-tsr .subheader h6 {
  font-size: 12px;
  font-weight: 300;
  color: #4F4F4F
}

.o-lt-tsr .image-card {
  order: 1;
  width: 100%
}

.o-lt-tsr .description {
  order: 4
}

@media (max-width: 768px) {
  .gc-account-leftbar .toggle-link img {
    display: none
  }

  .toggle-link.without-icon::before {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    background: center/24px url(https://www.svgrepo.com/show/509382/menu.svg) no-repeat #FFF0
  }

  .gc-account-leftbar .toggle-link {
    background: var(--white-color);
    border-top: 1px solid #D8D8D8
  }

  .dark-theme .gc-account-leftbar .toggle-link,
  .dark-theme .gc-account-leftbar .gc-account-user-menu {
    background: var(--black-color)
  }

  .menu-item.menu-item-theme {
    bottom: 50px
  }

  .gc-page-nav-items-menu a {
    background: #FFF0;
    color: #6B6B6B !important;
    font-size: 16px !important
  }

  .gc-account-leftbar .gc-account-user-menu {
    background: var(--white-color)
  }

  .gc-account-leftbar .gc-account-user-menu .menu-item:first-child {
    margin-top: 0 !important;
    padding-top: 10px !important
  }

  .gc-account-leftbar .notify-count {
    right: -6px !important;
    bottom: 0px !important;
    padding: 0 !important;
    background: #FFF0 !important;
    font-size: 16px;
    color: #000 !important
  }

  .stream-table {
    margin: 0 auto 50px !important
  }

  .stream-table tr {
    flex: 1 1 51% !important;
    margin: 0 auto 25px !important
  }

  .stream-table tr td {
    height: auto !important;
    min-height: auto !important
  }
}